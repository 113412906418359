export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function unawaited(_: Promise<unknown>) {}

export function roll(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function safeSetLocalStorage(k: string, v: unknown) {
  try {
    localStorage.setItem(k, JSON.stringify(v));
  } catch {
    // noop
  }
}

export function safeGetLocalStorage(k: string): unknown | null {
  try {
    return JSON.parse(localStorage.getItem(k));
  } catch {
    return null;
  }
}

export function execAfterDomContentLoaded(afterContentLoaded: EventListener) {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", afterContentLoaded);
  } else {
    afterContentLoaded(null);
  }
}

export function replaceAt(
  s: string,
  index: number,
  replacement: string
): string {
  return (
    s.substring(0, index) +
    replacement +
    s.substring(index + replacement.length)
  );
}

// resolves once el is partially visible
export async function addAnimationClassOncePartiallyVisible(
  el: HTMLElement,
  cssAnimationClass: string,
  removeAnimationClassesAfter = false,
  autoOpacity = true
): Promise<void> {
  return new Promise((resolve) => {
    if (autoOpacity) {
      el.style.opacity = "0";
    }

    const threshold = 0.75;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= threshold) {
            observer.disconnect();
            el.classList.add(cssAnimationClass);
            if (autoOpacity) {
              el.style.opacity = "1";
            }
            resolve();
          }
        });
      },
      { threshold }
    );
    observer.observe(el);

    el.addEventListener(
      "animationend",
      async () => {
        if (removeAnimationClassesAfter) {
          await sleep(500);
          el.classList.remove("animate__animated", cssAnimationClass);
        }
      },
      { once: true }
    );
  });
}
