import {
  addAnimationClassOncePartiallyVisible,
  execAfterDomContentLoaded,
  sleep,
  unawaited,
} from "../../util";

let elMap: {
  sliderCont: HTMLElement;
  imageConts: NodeListOf<HTMLElement>;
  scrollerEl: HTMLElement;
};

let currentIndex = 3;

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    sliderCont: document.getElementById("slider-cont"),
    imageConts: document.querySelectorAll<HTMLElement>(".slider-image-cont"),
    scrollerEl: document.getElementById("slider-images-scroller"),
  };

  await addAnimationClassOncePartiallyVisible(
    elMap.sliderCont,
    "animate__slideInRight"
  );

  unawaited(beginAutoSlideLoop());
}

async function beginAutoSlideLoop() {
  let cloneIndex = 0;
  const initialImagesListCloned: Node[] = [];
  elMap.imageConts.forEach((el) =>
    initialImagesListCloned.push(el.cloneNode(true))
  );

  while (true) {
    // need to refresh this list every call
    const currentEl =
      document.querySelectorAll<HTMLElement>(".slider-image-cont")[
        currentIndex++
      ];
    currentEl.classList.add("current-col-img");
    scrollElIntoViewWithTransform(currentEl);

    let appendEl = initialImagesListCloned[cloneIndex++];
    if (!appendEl) {
      cloneIndex = 0;
      appendEl = initialImagesListCloned[cloneIndex++];
    }
    elMap.scrollerEl.appendChild(appendEl.cloneNode(true));

    await sleep(2000);
    currentEl.classList.remove("current-col-img");
  }
}

function scrollElIntoViewWithTransform(el: HTMLElement) {
  // this only works with manual transform
  elMap.scrollerEl.style.transform = `translateX(-${
    el.offsetLeft +
    el.querySelector("img").clientWidth / 2 -
    document.body.clientWidth / 2
  }px)`;
}
